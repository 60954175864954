import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {RangeValue} from "@ionic/core";
import {Platform, RangeCustomEvent} from "@ionic/angular";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {SportClubService} from "@app/pages/sport-club/sport-club.service";
import {CommunicationService} from "@app/helper/communication.service";
import {SportFacilityService} from "@app/services/sport-facility.service";
import {SportFacilityModel} from "@app/models/sport-facility.model";
import {SportFacilitySportService} from "@app/services/sport-facility-sport.service";
import {SportModel} from "@app/models/sport.model";
import {fadeInAnimation} from "@app/animations";
import {GlobalService} from "@app/services/global.service";
import {PromoService} from "@app/services/promo.service";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {SportClubModel} from "@app/models/sport-club.model";
import {Capacitor} from "@capacitor/core";
import {SfsModel} from "@app/models/sfs.model";
import {isPlatformServer, Location} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {filter} from "rxjs";
import {FileService} from "@app/services/file.service";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {GeolocationService} from "@app/services/geolocation.service";

@Component({
  selector: 'sport-card-component',
  templateUrl: './sport-card.component.html',
  styleUrls: ['./sport-card.component.scss'],
  animations: [fadeInAnimation]
})
export class SportCardComponent implements OnInit {

  @Input() sportClubId: number = null;
  @Input() sportFacilityId: number = null;
  @Input() areSFSVisible: boolean = true;
  @Input() sport: SportModel = null;
  @Input() placePosition: { lat: number; lng: number };
  @Output() radiusChanged: EventEmitter<any> = new EventEmitter();

  /* BOOL UTILITY */
  public isLoading: boolean = false;

  /* FILTER */
  public sort_property: string = 'recommendation';
  public sort_direction: string = 'DESC';
  public sort_direction_name: string = 'Più raccomandate';
  public radius: RangeValue = 10;
  public max_results: number = 5;
  public currentPage: number = 1;

  public isFilterOpen: boolean = false;

  public position: { lng: number; lat: number } = {lng: null, lat: null};

  /*private user_lat: any;
  private user_long: any;
  public recommended_for: string = '';
  public isRadiusFilterVisible: boolean = true;

  sport_facility_filter_number: any;
  sport_facility_filter_data: any;
  sport_club_filter_number: any;
  sport_club_filter_data: any;*/

  /* DATA */
  public sportItemData: SfsModel[] = [];
  public pageArray: number[];
  public sportFacilityArray: SportFacilityModel[];
  public sportClubArray: SportClubModel[];
  public sportArray: SportModel[] = [];
  public promo: boolean = false;
  public leftSideSize: number = 70;
  public rightSideSize: number = 30;
  public smallView: boolean;
  public selectInterface: string = 'popover';
  public selectMode: string = 'md';
  /* FILTER */
  public selectedSportArray: number[] = [];
  public checkedSportFacilityArray: number[] = [];
  public checkedSportClubArray: number[] = [];
  public showSportClub: boolean = true;
  public showSportFacility: boolean = true;
  /*DEVICE CHECK*/
  private isDesktop: boolean = true;

  constructor(
    public navigationRoute: NavigationRouteService,
    private sportClubService: SportClubService,
    private sportFacilityService: SportFacilityService,
    private communicationService: CommunicationService,
    public globalService: GlobalService,
    private promoService: PromoService,
    private platform: Platform,
    public breakpointObserver: BreakpointObserver,
    public sportFacilitySportService: SportFacilitySportService,
    private location: Location,
    private route: ActivatedRoute,
    private fileService: FileService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private geolocationService: GeolocationService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (Capacitor.isNativePlatform()) {
      this.isDesktop = false
      this.selectInterface = 'action-sheet'
      this.selectMode = 'ios'
    }
    this.breakpointObserver
      .observe(['(max-width: 991px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isFilterOpen = false
          this.smallView = true
          this.leftSideSize = 0
          this.rightSideSize = 100
        } else {
          this.isFilterOpen = true
          this.smallView = false
          this.leftSideSize = 70
          this.rightSideSize = 30
        }
      });
  }

  ngOnInit() {
    this.showSportClub = true;
    this.showSportFacility = true;
    if (this.sport) {
      this.selectedSportArray.push(this.sport.id)
      this.geolocationService.getUserPosition().then((GeoPosition) => {
        this.position = {
          lat: GeoPosition.coords.latitude,
          lng: GeoPosition.coords.longitude
        }
        this.radius = 9999;
        this.sort_direction = 'ASC';
        this.sort_property = 'distance';

        this.getSportFacilitySportData()
      }).catch((err) => {
        this.radius = 200;
        this.sort_direction = 'ASC';
        this.sort_property = 'distance';

        this.getSportFacilitySportData()
      })
    } else if (this.sportClubId && !this.sportFacilityId) {
      this.checkedSportClubArray.push(this.sportClubId)
      /*this.sportFacilityService.getSportFacilityBySportClub(sport_club_id).subscribe({
        next: (sportFacilityArray: SportFacilityModel[]) => {
          this.sportFacilityArray = sportFacilityArray
        },
        error: err => {
          console.error(err)
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      })
      this.sportFacilitySportService.getSportFacilitySportBySportClub(sport_club_id).subscribe({
        next: (SportFacilitySportArray: SfsModel[]) => {
          if (SportFacilitySportArray) {
            SportFacilitySportArray.forEach(SportFacilitySport => {
              if (!this.sportArray.includes(SportFacilitySport['sport'])) {
                this.sportArray.push(SportFacilitySport['sport']);
                this.sportArray = [...this.sportArray];
              }
            })
          }
        },
        error: err => {
          console.error(err)
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      })*/
      this.getSportFacilitySportData()
      this.showSportClub = false;
    } else if (this.sportFacilityId && !this.sportClubId) {
      this.checkedSportFacilityArray.push(this.sportFacilityId)
      this.showSportFacility = false;
      this.getSportFacilitySportData()
    } else if (this.sportFacilityId && this.sportClubId) {
      this.checkedSportFacilityArray.push(this.sportFacilityId)
      this.checkedSportClubArray.push(this.sportClubId)
      this.showSportFacility = false;
      this.getSportFacilitySportData()
    } else if (this.placePosition) {
      this.setNewPosition(this.placePosition, this.radius)
    } else {
      this.getSportFacilitySportData()
    }
  }

  getSportFacilitySportData() {
    this.isLoading = true;
    this.radiusChanged.emit(this.radius)
    this.sportItemData = []
    this.sportClubArray = []
    this.sportArray = []
    this.sportFacilityArray = []
    this.sportFacilitySportService.sfsSearch(this.max_results, this.currentPage, this.sort_property, this.sort_direction, this.promo, this.position.lat, this.position.lng, this.radius, this.selectedSportArray, this.checkedSportFacilityArray, this.checkedSportClubArray).subscribe({
      next: (SportFacilitySportArray: {
        elenco: SfsModel[],
        current_page: number,
        max_page: number,
        sport_facility: SportFacilityModel[],
        sport_club: SportClubModel[],
        sport: SportModel[]
      }) => {
        try {
          this.sportItemData = SportFacilitySportArray?.elenco
          this.sportArray = SportFacilitySportArray?.sport
          this.sportClubArray = SportFacilitySportArray?.sport_club
          this.sportFacilityArray = SportFacilitySportArray?.sport_facility
        } catch (error) {
          console.error(error)
          this.isLoading = false;
        } finally {
          this.isLoading = false;
          SportFacilitySportArray?.elenco.forEach(sportFacilitySport => {
            this.fileService.getImage('optimized', 'sport-facility-sport-cover', sportFacilitySport.id).pipe(filter(x => x !== null)).subscribe({
              next: () => {
                sportFacilitySport['has_image'] = true
              },
              error: err => {
                console.error(err)
              }
            })
          })
        }
        let max_page: number;
        max_page = SportFacilitySportArray?.max_page;
        this.pageArray = Array.from(Array(max_page).keys()).map(x => ++x)
        this.currentPage = SportFacilitySportArray?.current_page;
      },
      error: err => {
        console.error(err)
        this.isLoading = false;
      }
    })
  }

  setNewPosition(position: { lat: number, lng: number }, radius: RangeValue) {
    this.position.lat = position.lat
    this.position.lng = position.lng
    this.radius = radius
    this.getSportFacilitySportData()
  }

  setCurrentPage(page) {
    if (!isPlatformServer(PLATFORM_ID)) {
      const currentUrl = window.location.search
      if (currentUrl.includes('page=')) {
        const newUrl = currentUrl.replace('page=' + this.currentPage, 'page=' + page)
        this.currentPage = page
        this.location.replaceState(window.location.pathname + newUrl)
      }

      this.currentPage = page
      this.getSportFacilitySportData()
      this.scroll(page)
      this.scroll('top')
    }
  }

  scroll(id) {
    if (!isPlatformServer(PLATFORM_ID)) {
      if (id == 'top') {
        const anchor_top = document.getElementById(id);
        anchor_top.scrollIntoView({block: "start", behavior: "smooth"});
      }
      const anchor = document.getElementById(id);
      anchor.scrollIntoView({block: "center", behavior: "smooth"});
    }
  }

  onIonKnobMoveEnd(e: Event) {
    this.radius = (e as RangeCustomEvent).detail.value;
    this.getSportFacilitySportData()
  }

  changeSort(sort_property) {
    this.sort_property = sort_property
    this.getSportFacilitySportData()
  }

  changeMaxResults(max_results) {
    this.max_results = max_results
    this.currentPage = 1;
    this.getSportFacilitySportData()
  }

  onSortDirection(event) {
    this.sort_direction = event?.detail.value
    this.getSportFacilitySportData()
  }

  onSportFacilityCheck(id: number) {
    this.isLoading = true;
    try {
      if (this.checkedSportFacilityArray.indexOf(id) > -1) {
        const index: number = this.checkedSportFacilityArray.indexOf(id);
        this.checkedSportFacilityArray.splice(index, 1);
      } else {
        this.checkedSportFacilityArray.push(id)
      }
    } finally {
      this.getSportFacilitySportData()
    }
  }

  onSportClubCheck(id: number) {
    this.isLoading = true;
    try {
      if (this.checkedSportClubArray.indexOf(id) > -1) {
        const index: number = this.checkedSportClubArray.indexOf(id);
        this.checkedSportClubArray.splice(index, 1);
      } else {
        this.checkedSportClubArray.push(id)
      }
    } finally {
      this.getSportFacilitySportData()
    }
  }

  onSportSelected(selectedSportArray: SportModel[]) {
    this.isLoading = true;
    this.currentPage = 1;
    try {
      this.selectedSportArray = [];
      selectedSportArray.forEach(sport => {
        this.selectedSportArray.push(sport.id)
      })
    } finally {
      this.getSportFacilitySportData()
    }
    /*if (selectedSportArray.length > 0) {
      try {
        selectedSportArray.forEach(sport => {
          /!*
                    this.sportFacilitySportService.sfsSearch(Number(sport_club_id), this.max_results, this.currentPage, this.sort_property, this.sort_direction, sport.id, null, this.promo).subscribe({
                      next: (SportFacilitySportArray) => {
                        SportFacilitySportArray['elenco'].forEach(SportFacilitySportArray => {
                          this.sportItemData.push(SportFacilitySportArray)
                        })
                        let max_page = [];
                        max_page = SportFacilitySportArray['max_page'];
                        this.pageArray = Array.from(Array(max_page).keys()).map(x => ++x)
                        this.currentPage = SportFacilitySportArray['current_page'];
                      },
                      error: err => {
                        console.error(err)
                        this.isLoading = false;
                      }
                    })
          *!/
        })
      } catch (err) {
        console.error(err)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    } else {
      this.getSportFacilitySportData()
    }*/
  }

  /*onDeleteFilter() {
    this.communicationService.customErrorToast('Tutti i filtri sono stati tolti', 'secondary').then(() => {
      /!*this.radius = 5;*!/
      this.changeMaxResults(5)
      this.changeSort('recommendation')
      this.sort_direction = 'DESC';
      this.setCurrentPage(1)
    })
  }*/

  onPromoFilter() {
    this.promo = !this.promo
    /*if (this.checkedSportFacilityArray.indexOf(id) > -1) {
      const index: number = this.checkedSportFacilityArray.indexOf(id);
      this.checkedSportFacilityArray.splice(index, 1);
    } else {
      this.checkedSportFacilityArray.push(id)
    }*/
    try {
      this.getSportFacilitySportData()
      /*this.sportItemData.forEach(sportFacilitySport => {
        if (!sportFacilitySport.has_promo) {
          this.sportItemData.slice(this.sportItemData.indexOf(sportFacilitySport), 1)
        }
      })*/
    } catch (err) {
      console.error(err)
    } finally {
    }
  }

  onFilterCheck(event) {
    console.log(event)
  }

  setFilterModalOpen() {
    if (this.smallView) {
      this.leftSideSize = 0
      this.rightSideSize = 100
    } else {
      this.leftSideSize = 70
      this.rightSideSize = 30
    }
    this.isFilterOpen = !this.isFilterOpen;
  }

  requestContacts() {
    this.reCaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        this.communicationService.notify('COMMUNICATION.ACTIVITY_REQUEST', 'success').then(() => {
          this.globalService.requestContacts(this.sportClubId, 'sc', token).subscribe();
        })
      });
  }

  requestOperatorPayment() {
    this.reCaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        this.communicationService.notify('COMMUNICATION.CONTACT_OPERATOR_RECHARGE_POCKET', 'success').then(() => {
          this.globalService.requestOperatorRechargePocketMoney(this.sportFacilityId ? this.sportFacilityId : this.sportClubId, this.sportFacilityId ? 'sf' : 'sc', token).subscribe();
        })
      });
  }

}
