<as-split direction="horizontal" [disabled]="true" [useTransition]="true" id="top">
  <as-split-area [size]="leftSideSize">
    <!-- FILTERS -->
    <div class="row g-0 align-items-center gap-3 justify-content-center mb-2">
      <div class="col">
        <ion-select [interface]="selectInterface" cancelText="Annulla" label="Per pagina:" labelPlacement="floating"
                    value="{{max_results}}"
                    (ionChange)="changeMaxResults($event.detail.value)" [mode]="selectMode" class="fit-select">
          <ion-select-option value="5">5 risultati</ion-select-option>
          <ion-select-option value="10">10 risultati</ion-select-option>
          <ion-select-option value="20">20 risultati</ion-select-option>
          <ion-select-option value="30">30 risultati</ion-select-option>
        </ion-select>
      </div>
      <div class="col">
        <ion-select [interface]="selectInterface" placeholder="Raccomandazioni" value="{{sort_property}}"
                    cancelText="Annulla" (ionChange)="changeSort($event.detail.value)" [mode]="selectMode"
                    class="fit-select" label="Ordina per:" labelPlacement="floating">
          <ion-select-option
            value="recommendation">{{ 'RECOMMENDED_FOR.RECOMMENDATION' | translate }}
          </ion-select-option>
          <ion-select-option *ngIf="showSportFacility && showSportClub" @fadeIn value="distance">
            Distanza
          </ion-select-option>
          <ion-select-option value="alphabetical">Alfabetico</ion-select-option>
        </ion-select>
      </div>
      <div class="col">
        <ion-select (ionChange)="onSortDirection($event)" [interface]="selectInterface" [mode]="selectMode"
                    cancelText="Annulla" class="fit-select" label="Mostra prima:"
                    labelPlacement="floating" placeholder="{{sort_direction_name}}" value="{{sort_direction}}">
          <ion-select-option *ngIf="sort_property === 'recommendation'" value="DESC">Più raccomandate
          </ion-select-option>
          <ion-select-option *ngIf="sort_property === 'recommendation'" value="ASC">Meno raccomandate
          </ion-select-option>
          <ion-select-option *ngIf="sort_property === 'distance'" value="DESC">Più lontane
          </ion-select-option>
          <ion-select-option *ngIf="sort_property === 'distance'" value="ASC">Più vicine
          </ion-select-option>
          <ion-select-option *ngIf="sort_property === 'alphabetical'" value="DESC">Z-a
          </ion-select-option>
          <ion-select-option *ngIf="sort_property === 'alphabetical'" value="ASC">A-z
          </ion-select-option>
        </ion-select>
      </div>
      <!--<div class="col-12 col-sm-auto d-flex justify-content-center align-items-center filter-button-margin">
        <ion-button class="ion-btn-primary no-padding-button aspect-ratio-1 m-0"
                    (click)="onSortDirection()">
          <ion-icon name="arrow-up" class="size-30 p-2" @fadeIn *ngIf="sort_direction === 'ASC'"></ion-icon>
          <ion-icon name="arrow-down" class="size-30 p-2" @fadeIn *ngIf="sort_direction === 'DESC'"></ion-icon>
        </ion-button>-->
      <ion-button (click)="setFilterModalOpen()" class="ion-btn-secondary ion-hide-lg-up">
        <ion-icon class="me-2" name="filter"></ion-icon>
        filtri
      </ion-button>
      <!--</div>
      <ion-button class="fit-reset-filter no-padding-button aspect-ratio-1 col-auto mt-2" (click)="onDeleteFilter()">
        <ion-icon name="trash" class="size-30 p-2"></ion-icon>
      </ion-button>-->
    </div>
    <!-- SPORT FACILITY SPORT -->
    <div class="sport-card-container mt-3">
      <div *ngIf="!isLoading; else skelly">
        <div @fadeIn *ngIf="sportItemData?.length > 0">
          <div *ngIf="areSFSVisible" @fadeIn>
            <div *ngFor="let sportItem of sportItemData"
                 [routerLink]="'/impianti/' + sportItem?.sport_facility?.slug + '/'+ sportItem?.id+ '/'+ sportItem.sport.slug"
                 class="mt-2 fit-rounded"
                 title="{{sportItem?.sport.name}}">
              <a class="text-body box-attivita" data-toggle="tooltip">
                <div class="row p-0 g-0 py-2">
                  <div class="col-12 col-md-5 centered-start">
                    <h4 class="bold ion-color-primary m-0">{{ sportItem.sport?.name }}</h4>
                  </div>
                  <div class="col-12 col-md-7 ">
                    <div class="justify-content-start justify-content-md-end align-content-center gap-2 row g-0">
                      <div *ngIf="sportItem?.tot_recommendation"
                           class="col-auto badge ion-bg-tertiary light p-2 mt-3 mt-md-0">
                        <ion-icon class="me-2" name="bullhorn"></ion-icon>
                        Raccomandato {{ sportItem?.tot_recommendation }} VOLTE
                      </div>
                      <div *ngIf="sportItem?.distance"
                           class="col-auto badge ion-bg-secondary light p-2 mt-3 mt-md-0">
                        <ion-icon name="location-dot"></ion-icon>
                        {{ sportItem?.distance?.toFixed(2) }} km
                      </div>
                      <div
                        *ngIf="sportItem.sport_facility?.rank_position <= 3 && sportItem.sport_facility?.rank_position !== 0"
                        class="col-auto badge ion-bg-primary light p-2 mt-3 mt-md-0">
                        TOP 3!
                      </div>
                      <div
                        *ngIf="sportItem.sport_facility?.rank_position <= 10 && sportItem.sport_facility?.rank_position > 4"
                        class="col-auto badge ion-bg-primary light p-2 mt-3 mt-md-0">
                        TOP 10!
                      </div>
                      <div
                        *ngIf="sportItem.sport_facility?.rank_position <= 20 && sportItem.sport_facility?.rank_position > 11"
                        class="col-auto badge ion-bg-primary light p-2 mt-3 mt-md-0">
                        TOP 20!
                      </div>
                      <div class="col-auto mt-3 mt-md-0 d-flex align-content-center">
                        <ion-icon *ngIf="sportItem?.has_image" @fadeIn class="size-23 ion-color-quaternary"
                                  name="images"></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <p *ngIf="sportItem?.most_recommended_for" class="my-0 mt-2">
                  Gli utenti lo raccomandano per
                  <span class="bold text-uppercase">{{ sportItem.most_recommended_for }}</span>
                </p>
                <p *ngIf="!sportItem?.most_recommended_for" class="my-0 mt-2">
                  Ancora nessuna raccomandazione, se hai frequentato questo corso <b>raccomandalo</b>
                </p>
                <a [routerLink]="'/impianti/' + sportItem?.sport_facility.id + '/'+ sportItem?.sport_facility.slug">
                  Impianto sportivo: <b>{{ sportItem.sport_facility?.name }}</b><br>
                </a>
                Indirizzo:
                <b>{{ sportItem.sport_facility?.address + ' ' + sportItem.sport_facility?.civic_number + ', ' + sportItem.sport_facility?.cap + ' ' + sportItem.sport_facility?.city_name + ' ' + sportItem.sport_facility?.state_code }}</b><br>
                <a [routerLink]="'/profilo/operatore/' + sportItem?.sport_club.operator_id">
                  Società sportiva: <b>{{ sportItem.sport_club?.name }}</b>
                </a>
                <div *ngIf="sportItem?.has_promo" class="fit-ribbon mt-3">
                  <ion-icon class="ms-3" name="tag"></ion-icon>
                  <span class="px-2">Promozione in corso</span>
                </div>
              </a>
            </div>
          </div>
          <div *ngIf="!areSFSVisible" @fadeIn>
            <div class="my-2 fit-rounded text-center ion-bg-secondary-tint ion-color-secondary py-5">
              <ion-button (click)="requestOperatorPayment()" class="montserrat bold" color="secondary"
                          data-original-title="Richiedi di vedere le attività" data-toggle="tooltip"
                          title="Richiedi di vedere le attività">
                <ion-icon class="me-2" name="circle-question"></ion-icon>
                Richiedi di vedere le attività
              </ion-button>
            </div>
          </div>
        </div>
        <div *ngIf="sportItemData?.length < 1 && (sportFacilityId || sportClubId)" @fadeIn>
          <div class="my-2 fit-rounded text-center ion-bg-secondary-tint ion-color-secondary py-5">
            <h2 class="mt-3 bold ion-color-secondary">Nessun risultato trovato</h2>
            <ion-button (click)="requestContacts()" class="montserrat bold" color="secondary"
                        data-original-title="Richiedi l'inserimento delle attività" data-toggle="tooltip"
                        title="Richiedi l'inserimento dei contatti">
              <ion-icon class="me-2" name="circle-question"></ion-icon>
              Richiedi inserimento attività
            </ion-button>
          </div>
        </div>
      </div>
      <ng-template #skelly>
        <ngx-skeleton-loader class="p-0" *ngFor="let dummy of globalService.generateFake(5)"
                             [theme]="{height: '186px','margin': 0,'margin-top': '8px' , 'background-color': '#eaeef6'}">
        </ngx-skeleton-loader>
      </ng-template>
    </div>
    <!-- PAGINATION BUTTONS -->
    <div class="fit-pagination pagination mx-5 mt-5">
      <ion-button (click)="setCurrentPage(currentPage - 1)" *ngIf="currentPage > 1"
                  class="m-0 border fit-rounded" color="secondary" fill="clear">
        <
      </ion-button>
      <ul *ngIf="pageArray?.length > 1" class="pagination">
        <li (click)="setCurrentPage(page)" *ngFor="let page of pageArray" [id]="page"
            [ngClass]="{'current_page': currentPage == page}"
            class="page-item page-link">
          {{ page }}
        </li>
      </ul>
      <ion-button (click)="setCurrentPage(currentPage + 1)" *ngIf="currentPage < pageArray?.length"
                  class="m-0 border fit-rounded"
                  color="secondary"
                  fill="clear">
        >
      </ion-button>
    </div>
  </as-split-area>
  <as-split-area [size]="rightSideSize" @fadeIn [visible]="isFilterOpen">
    <div class="ps-2 filter-container">
      <div class="d-flex justify-content-between">
        <h5 class="bold align-self-center m-0 mt-4">Filtra attività per:</h5>
        <ion-button class="ion-btn-secondary ms-2 mt-4 ion-hide-lg-up"
                    (click)="setFilterModalOpen()">
          Vedi risultati
        </ion-button>
      </div>
      <div @fadeIn *ngIf="position.lng">
        <hr class="my-3"/>
        <h4 class="my-3 bold" slot="label">Raggio
          <span class="light size-16"> ({{ radius === 9999 ? '∞' : radius }} km)</span>
        </h4>
        <ion-range (ionKnobMoveEnd)="onIonKnobMoveEnd($event)"
                   step="5" class="px-2 py-0"
                   [pin]="true" [value]="radius"
                   [snaps]="true" [min]="5" [max]="50"
                   aria-label="raggio">
        </ion-range>
        <hr class="my-3"/>
      </div>
      <h4 *ngIf="!sport" class="my-3 bold">Sport
        <span class="light size-16"> ({{ sportArray?.length }})</span>
      </h4>
      <ng-select *ngIf="sportArray && !sport" @fadeIn
                 [items]="sportArray"
                 bindLabel="name"
                 bindValue="id"
                 [multiple]="true"
                 placeholder="Nessun filtro per sport selezionato"
                 clearAllText="Deseleziona"
                 class="mb-4"
                 (change)="onSportSelected($event)"
      >
      </ng-select>
      <div *ngIf="showSportFacility && !isLoading" @fadeIn>
        <hr class="mb-1 mt-3"/>
        <h4 class="mt-3 bold">Luoghi Sportivi
          <span class="light size-16"> ({{ sportFacilityArray?.length }})</span>
        </h4>
        <div class="my-4">
          <div class="form-check" *ngFor="let sportFacility of sportFacilityArray">
            <input class="form-check-input" type="checkbox"
                   [checked]="checkedSportFacilityArray.includes(sportFacility.id)"
                   (change)="onSportFacilityCheck(sportFacility.id)" [id]="sportFacility.id">
            <label class="form-check-label no-select pointer" for="{{sportFacility.id}}">
              {{ sportFacility.name }} <small *ngIf="radius">({{ sportFacility?.distance?.toFixed(2) }} km)</small>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="showSportClub && !isLoading" @fadeIn>
        <hr class="mb-1 mt-3"/>
        <h4 class="mt-3 bold">Associazioni sportive
          <span class="light size-16"> ({{ sportClubArray?.length }})</span>
        </h4>
        <div class="my-4">
          <div class="form-check" *ngFor="let sportClub of sportClubArray">
            <input class="form-check-input" type="checkbox"
                   [checked]="checkedSportClubArray.includes(sportClub.id)"
                   (change)="onSportClubCheck(sportClub.id)" [id]="sportClub.id">
            <label class="form-check-label no-select pointer" for="{{sportClub.id}}">
              {{ sportClub.name }}
            </label>
          </div>
        </div>
      </div>
      <div @fadeIn *ngIf="isLoading">
        <hr class="mb-1 mt-3"/>
        <h4 class="mt-3 bold">Luoghi Sportivi
        </h4>
        <ngx-skeleton-loader [theme]="{margin: '0', 'margin-top': '8px', height: '30px', 'background-color': '#edeeef'}"
                             count="2">
        </ngx-skeleton-loader>
        <hr class="mb-1 mt-3"/>
        <h4 class="mt-3 bold">Associazioni sportive
        </h4>
        <ngx-skeleton-loader [theme]="{margin: '0', 'margin-top': '8px', height: '30px', 'background-color': '#edeeef'}"
                             count="2">
        </ngx-skeleton-loader>
      </div>
      <hr class="mb-1 mt-3"/>
      <!--<h4 class="mt-3 bold">Tipologia di lezione</h4>
      <div class="my-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="online"
                 (change)="onFilterCheck($event)" id="online">
          <label class="form-check-label no-select pointer" for="online">
            Online
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="offline"
                 (change)="onFilterCheck($event)" id="offline">
          <label class="form-check-label no-select pointer" for="offline">
            Offline
          </label>
        </div>
      </div>
      <hr class="mb-1 mt-3"/>
      <h4 class="mt-3 bold">Con promozioni attive</h4>
      <div class="my-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="promo"
                 (change)="onPromoFilter()" id="promo" [(ngModel)]="promo">
          <label class="form-check-label no-select pointer" for="promo">
            In promozione
          </label>
        </div>
      </div>
      <hr class="mb-1 mt-3"/>
      <h4 class="mt-3 bold">Foto & video</h4>
      <div class="my-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="hasPhoto"
                 (change)="onFilterCheck($event)" id="hasPhoto">
          <label class="form-check-label no-select pointer" for="hasPhoto">
            Impianti con foto
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="hasVideo"
                 (change)="onFilterCheck($event)" id="hasVideo">
          <label class="form-check-label no-select pointer" for="hasVideo">
            Impianti con video
          </label>
        </div>
      </div>-->
    </div>
  </as-split-area>
</as-split>
